import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin)

export default class extends Controller {
    static targets = ['item', 'label', 'drawer', 'image'];

    tl = gsap.timeline();

    connect() {
        this.labelTargets.forEach((label) => {
            label.addEventListener("click", this.toggleDrawer);
            label.addEventListener("keydown", (event) => {
                if (event.key === "Enter") {
                    this.toggleDrawer(event);
                }
            });
        });

        this.itemTargets.forEach((item) => {
            if (item.classList.contains("initially-open")) {
                const drawer = item.querySelector(".accordion__list-item__drawer");
                this.tl.set(drawer, {
                    height: 'auto',
                    opacity: 1,
                });
            }
        });
    }

    initialize() {
        this.tabIndexOff(this.element);
    }

    tabIndexOff(element) {
        element.querySelectorAll('a').forEach(link => {
            link.setAttribute("tabindex", "-1");
        });
        
        element.querySelectorAll('button').forEach(link => {
            link.setAttribute("tabindex", "-1");
        });
    }

    tabIndexOn(element) {
        element.querySelectorAll('a').forEach(link => {
            link.setAttribute("tabindex", "0"); 
        });
        
        element.querySelectorAll('button').forEach(link => {
            link.setAttribute("tabindex", "0"); 
        });
    }

    toggleDrawer = (event) => {
        // Find the drawer element within the same item container
        const item = event.currentTarget.closest(".accordion__list-item");
        const drawer = item.querySelector(".accordion__list-item__drawer");
        
        const itemIndex = item.dataset.index;
        this.imageTargets.forEach((image) => {
            if (image.dataset.index === itemIndex) {
                image.classList.add("visible");
            } else {
                image.classList.remove("visible");
            }
        });

        const openItems = this.itemTargets.filter((item) => {
            return item.classList.contains("open") || item.classList.contains("initially-open");
        });

        if (drawer && !item.classList.contains("open") && !item.classList.contains("initially-open")) {
            const headerHeight = document.querySelector('.header').offsetHeight
            const offset = 94; // + headerHeight;

            this.tl.to(this.drawerTargets, {
                height: 0,
                opacity: 0,
                overflow: 'hidden',
                duration: .25,
            });

            this.tl.to(window, { duration: .25, scrollTo: { y: item, offsetY: offset }});

            this.tl.to(drawer, {
                height: 'auto',
                overflow: 'visible',
                duration: .25,
            }, '<');

            this.tl.to(drawer, {
                opacity: 1,
                duration: .25,
            });


            openItems.forEach((openItem) => {
                openItem.classList.remove("initially-open");
                openItem.classList.remove("open");
                this.tabIndexOff(openItem);
            });

            item.classList.toggle("open");
            this.tabIndexOn(item);
        }
    }
}
