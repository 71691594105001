import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

export default class extends Controller {
  static targets = ["drawer", "toggle", "list", "child", "image"];

  connect() {
  }

  initialize() {
    this.mm = gsap.matchMedia();
    this.breakpoint = '(max-width: 799px)';

    this.childTargets.forEach((child) => {
      this.tabIndexOff(child);
    });
  }

  tabIndexOff(wrapper) {
    wrapper.querySelectorAll("a").forEach((link) => {
      link.setAttribute("tabindex", "-1");
    });

    wrapper.querySelectorAll("button").forEach((link) => {
      link.setAttribute("tabindex", "-1");
    });
  }

  tabIndexOn(wrapper, weight = 0) {
    wrapper.querySelectorAll("a").forEach((link) => {
      link.setAttribute("tabindex", weight);
    });

    wrapper.querySelectorAll("button").forEach((link) => {
      link.setAttribute("tabindex", weight);
    });

    wrapper.querySelector("a, button").focus();
  }

  switchImage(event) {
    const index = event.params.index;

    this.imageTargets.forEach((image) => {
      if (index == image.dataset.index) {
        image.classList.add("is-visible");
      } else {
        image.classList.remove("is-visible");
      }
    });
  }

  openDrawer() {
    window.lenis.stop();
    this.element.classList.add("is-active");
  
    gsap.to(this.drawerTarget, 
      {
        duration: 0.7,
        x: '0%',
        opacity: 1,
        ease: "power4.out",
      }
    );
  }
  
  closeDrawer() {
    window.lenis.start();
  
    gsap.to(this.drawerTarget, {
      duration: 0.5,
      x: '100%',
      opacity: 0,
      ease: "power4.in",
      onComplete: () => {
        this.element.classList.remove("is-active");
      },
    });
  }
  

  // openDrawer() {
  //   window.lenis.stop();
  //   this.element.classList.add("is-active");

  //   gsap.to(this.drawerTarget, {
  //     duration: 0.5,
  //     x: '0%',
  //     ease: "ease-out",
  //   });
  // }

  // closeDrawer() {
  //   window.lenis.start();

  //   gsap.to(this.drawerTarget, {
  //     duration: 0.5,
  //     x: '100%',
  //     ease: "ease-in",
  //     onComplete: () => {
  //       this.element.classList.remove("is-active");
  //     },
  //   });
  // }
}
