import { Application } from "@hotwired/stimulus";
// import * as Turbo from "@hotwired/turbo";

// Import stimulus controllers
import HeaderController from "/components/header/header_controller";
import NavigationController from "/components/navigation/navigation_controller";
import VideoController from "/components/video/video_controller";
import VideoModalController from "/components/video-modal/video-modal_controller";
import PopupController from "/components/popup/popup_controller";

// import FiltersController from "/blocks/data/listing/filters_controller";
// import NestedCheckboxController from "/components/filters/taxonomy/nested-checkbox/nested-checkbox_controller";

import HomepageHero from "/blocks/basic/homepage-hero/homepage-hero_controller";
import AccordionController from "/blocks/content/accordion/accordion_controller";


import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// TODO: required in forminator and should be loaded only in specific areas
import jQuery from "cash-dom";

// Turbo setup
// Turbo.setProgressBarDelay(50);

// window.freeze = () => {
//   window.Turbo.navigator.currentVisit.scrolled = true;
//   document.removeEventListener("turbo:render", freeze);
// };

// Stimulus
const Stimulus = Application.start();
window.Stimulus = Stimulus;

// Register Stimulus controllers
Stimulus.register("header", HeaderController);
Stimulus.register("navigation", NavigationController);
Stimulus.register("video", VideoController);
Stimulus.register("video-modal", VideoModalController);
Stimulus.register("popup", PopupController);

// Stimulus.register("filters", FiltersController);
// Stimulus.register("nested-checkbox", NestedCheckboxController);

Stimulus.register("homepage-hero", HomepageHero);
Stimulus.register("accordion", AccordionController);

// Container Queries Support Test
// const supportsContainerQueries = "container" in document.documentElement.style;

// Conditional Import
// if (!supportsContainerQueries) {
// import("https://cdn.skypack.dev/container-query-polyfill");
// }

// document.addEventListener("turbo:before-render", (event) => {
//     /*
//      * When turbolinks navigates, we will make sure the current header classnames 'is-stuck' or 'is-off-screen'
//      * are carried over to the next render to prevent a flash of nav, or a nav appearing when it wasn't here before
//      */

//     const currentHeaderClasses = document.querySelector("header.header").className;
//     console.log(currentHeaderClasses);
//     event.detail.newBody.querySelector("header.header").className = currentHeaderClasses;
// });

document.addEventListener("DOMContentLoaded", () => {

    // below line is breaking nested scrolling and doesn't seem to be needed
    // ScrollTrigger.normalizeScroll(true)

    const lenis = new Lenis({
        smoothWheel: true,
        // smoothTouch: true,
    })

    lenis.on('scroll', ScrollTrigger.update)

    gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)



    // above seems to be working in FF too
    // // if firefox
    // if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    //     function raf(time) {
    //         lenis.raf(time)
    //         requestAnimationFrame(raf)
    //     }
    //     requestAnimationFrame(raf)
    // } else {
    //     ScrollTrigger.normalizeScroll(true)

    //     lenis.on('scroll', ScrollTrigger.update)

    //     gsap.ticker.add((time)=>{
    //         lenis.raf(time * 1000)
    //     })

    //     gsap.ticker.lagSmoothing(0)
    // }

    // scrollToStoredPosition();

    window.lenis = lenis
});

// Function to animate smooth scrolling to a target position
function scrollToPosition(targetPosition) {
  gsap.to(window, {
    duration: .5,
    scrollTo: targetPosition,
    ease: 'power2.out',
  });
}

// Function to check for stored scroll position and scroll to it
function scrollToStoredPosition() {
  // Retrieve the stored scroll position from sessionStorage
  const storedPosition = sessionStorage.getItem('scrollPosition');

  // Check if a stored position exists and it's a valid number
  if (storedPosition && !isNaN(storedPosition)) {
    // Convert the stored position to a number
    const targetPosition = parseFloat(storedPosition);

    // Animate smooth scrolling to the stored position
    scrollToPosition(targetPosition);

    // Clear the stored scroll position
    sessionStorage.removeItem('scrollPosition');
  }
}


