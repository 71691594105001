import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toggle", "box"]

    static values = {
        hash: String
    }

    connect() {
        this.toggleTargets.forEach((element) => {
            element.addEventListener("click", (e) => {
                e.preventDefault()
                this.toggle()
            })
        }); 

        // console.log('this.hashValue: ' + this.hashValue, this.element);
    }
    
    toggle() {
        this.boxTarget.classList.toggle("hidden")

        if (this.hashValue) {
            if (this.boxTarget.classList.contains("hidden")) {
                // console.log('Event: pause-video-modal-' + this.hashValue);
                document.dispatchEvent(new CustomEvent('pause-video-modal-' + this.hashValue));
            } else {
                // console.log('Event: play-video-modal-' + this.hashValue);
                document.dispatchEvent(new CustomEvent('play-video-modal-' + this.hashValue));
            }
        }
    }
}
