import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

export default class extends Controller {
  static targets = ["slider", "title", "titleSlide"]

  connect() {
    const slider = new Swiper(this.sliderTarget, {
      modules: [Autoplay],
      freeMode: true,
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      loopAdditionalSlides: 1,
      spaceBetween: 16,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      speed: 5000,
      allowTouchMove: false,
      breakpoints: {
        480: {
          slidesPerView: 3,
          spaceBetween: 20,
          loopAdditionalSlides: 1,
        },
      },
    });

    if (this.hasTitleTarget) {
      this.animateTitle();
    }
  }

  animateTitle() {
    const titleSlider = new Swiper(this.titleTarget, {
      modules: [Autoplay],
      slidesPerView: 1,
      spaceBetween: 16,
      height: 96, // 48
      loop: true,
      speed: 1000,
      initialSlide: this.titleSlideTargets.length - 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        reverseDirection: true,
      },
      allowTouchMove: false,
      direction: "vertical",
      breakpoints: {
        700: {
          height: 66,
        }
      },
    });
  }
}
