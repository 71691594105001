import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { last } from "lodash";

export default class extends Controller {
  connect() {
    gsap.registerPlugin(ScrollTrigger);

    let lastScrollY = 0;
    let isHidden = false;

    ScrollTrigger.create({
      start: "top+=200px top",
      end: "bottom bottom",
      onEnter: () => {
        gsap.to(this.element, {
          y: "-100%",
          duration: 0,
          onComplete: () => {
            this.element.classList.add("is-fixed");
          }
        });
      },
      onLeaveBack: (self) => {
        gsap.to(this.element, {
          y: "-100%",
          opacity: 0,
          // position: "absolute",
          duration: .5,
          ease: "power2.out",
          onComplete: () => {
            this.element.classList.remove("is-fixed");
            this.element.classList.remove("is-hidden");
            this.element.style.transform = "translateY(0)";
            // this.element.style.opacity = 0;
          }
        })
        gsap.to(this.element, {
          opacity: 1,
          duration: 1,
          delay: .6,
          ease: "power2.out"
        });
      },
      onUpdate: (self) => {
        if (self.scroll() > lastScrollY && !isHidden) { // Scrolling down
          isHidden = true;
          gsap.to(this.element, {
            y: "-100%",
            duration: 0.5,
            ease: "power2.out",
            onComplete: () => {
              this.element.classList.add("is-hidden");
            }
          });
        } else if (self.scroll() < lastScrollY && isHidden) { // Scrolling up
          isHidden = false;
          gsap.to(this.element, {
            y: "0%",
            duration: 0.5,
            ease: "power2.out",
            onComplete: () => {
              this.element.classList.remove("is-hidden");
            }
          });
        }
        
        lastScrollY = self.scroll();
      }
    });
  }
}


































// import { Controller } from "@hotwired/stimulus";
// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";

// export default class extends Controller {
//   static targets = ["header"];

//   connect() {
//     gsap.registerPlugin(ScrollTrigger);

//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: this.element,
//         start: "top+=120px",
//         // end: "+=100vh",
//         toggleActions: "play none none reverse",
//         onEnter: () => {
//           tl.timeScale(1.0);
//         },
//         onEnterBack: () => {
//           tl.timeScale(5.0);
//         },
//         onUpdate: (self) => {
//           // Determine scroll direction
//           if (self.direction > 0) { // Scrolling down
//             // Hide element with animation
//             gsap.to(this.element, {
//               translateY: `-100%`,
//               duration: 0.5,
//               onComplete: () => {
//                 this.element.classList.add("is-hidden");
//               }
//             });
//           } else { // Scrolling up
//             // Show element with animation
//             gsap.to(this.element, {
//               translateY: `0`,
//               duration: 0.5,
//               onComplete: () => {
//                 this.element.classList.remove("is-hidden");
//               }
//             });
//           }
//         }
//       },
//     });

//     tl.to(this.element, {
//       translateY: "-100%",
//       // backgroundColor: "#f5f4f4",
//       onComplete: () => {
//         this.element.classList.add("is-fixed");
//       },
//       onReverseComplete: () => {
//         this.element.classList.remove("is-fixed");
//       },
//     });

//     tl.to(this.element, {
//       position: "fixed",
//       top: 0,
//     });

//     tl.to(this.element, {
//       translateY: "0%",
//       duration: 0.25,
//     });
//   }
// }
