import { Controller } from "@hotwired/stimulus";
import { set } from "lodash";

export default class extends Controller {
    static values = {
        id: String,
    };
    
    connect() {
        const storage = localStorage.getItem("popup");

        console.log("popup_controller.js: ", storage, this.idValue);

        if (!storage || storage !== this.idValue) {
            this.open();
        } else if (storage === this.idValue) {
            this.close();
        }
    }

    open() {
        this.element.classList.add("is-open");
        this.tabIndexOn(10);
    }

    close() {
        console.log("close");
        this.element.classList.remove("is-open");
        localStorage.setItem("popup", this.idValue);
        this.tabIndexOff();
    }

    tabIndexOff() {
        this.element.querySelectorAll('a').forEach(link => {
            link.setAttribute("tabindex", "-1");
        });
        
        this.element.querySelectorAll('button').forEach(link => {
            link.setAttribute("tabindex", "-1");
        });
    }

    tabIndexOn(lvl = 0) {
        this.element.querySelectorAll('a').forEach(link => {
            link.setAttribute("tabindex", lvl); 
        });
        
        this.element.querySelectorAll('button').forEach(link => {
            link.setAttribute("tabindex", lvl); 
        });
    }
}
